<template>
    <div style="height: 100%; width: 100%; background-color: #f4f4f4">
        <Horizontal :isSuccess="isSuccess" v-if="$store.getters.currentPageVersion === 'Horizontal'"></Horizontal>
        <Vertical :isSuccess="isSuccess" v-else></Vertical>
    </div>
</template>

<script>
    import Horizontal from "@/components/Customer/Index/Horizontal/Index.vue";
    import Vertical from "@/components/Customer/Index/Vertical/Index.vue";
    export default {
        components: {
            Horizontal,
            Vertical,
        },
        data() {
            return {
                isSuccess: 1,
            };
        },
        created() {
            setTimeout(() => {
                this.init();
            }, 1500);
        },
        methods: {
            init() {
                console.log(this.$store.state.qiyukfUrl);
                switch (this.$store.state.serviceType) {
                    case "url":
                        this.isSuccess = this.$store.state.serviceConfig ? 2 : 3;
                        break;
                    case "qykf":
                        this.isSuccess = this.$store.state.qiyukfUrl ? 2 : 3;
                        break;
                    case "html":
                        this.isSuccess = this.$store.state.serviceConfig ? 2 : 3;
                        break;
                    case "dezhu":
                        this.isSuccess = this.$store.state.qiyukfUrl ? 2 : 3;
                        break;
                    default:
                        break;
                }
            },
        },
    };
</script>

<style scoped>
</style>